import React, { useEffect, useState } from 'react';
import SlDialog from '@shoelace-style/shoelace/dist/react/dialog/index.js';
import SlButton from '@shoelace-style/shoelace/dist/react/button/index.js';

import styles from './Disclaimer.module.css';
import { disclaimerStorage } from '../configs';

const Disclaimer: React.FC = () => {
  const [disclaimerConfirmation, setDisclaimerConfirmation] = useState(
    disclaimerStorage.value,
  );

  useEffect(() => {
    // Update disclaimer confirmation value on mount
    setDisclaimerConfirmation(localStorage.getItem(disclaimerStorage.key));
  }, []);
  return (
    <>
      {disclaimerConfirmation !== disclaimerStorage.value ? (
        <SlDialog
          className={styles['disclaimer']}
          label="Disclaimer"
          open
          data-e2e="disclaimer"
          onSlRequestClose={(event) => event.preventDefault()}
        >
          <div className={styles['disclaimer__container']}>
            <p className={styles['disclaimer__group']}>
              I acknowledge that I am bound by confidentiality obligations
              imposed through my employment or contractual agreement with Roche
              in connection with my access to confidential information,
              including Cortex Platform and its contents.
            </p>

            <p className={styles['disclaimer__group']}>
              Before entering Cortex Platform, I confirm that I understand that
              my activities within Cortex Platform may be monitored, and all
              contents and passwords are confidential information, and that
              unauthorized disclosure or use of such confidential information
              may result in termination of my employment or services and/or
              legal action.
            </p>
          </div>

          <div slot="footer" className={styles['disclaimer__footer']}>
            <SlButton
              variant="secondary"
              className={styles['disclaimer__confirm']}
              data-e2e="disclaimerConfirmButton"
              onClick={() => {
                localStorage.setItem(
                  disclaimerStorage.key,
                  disclaimerStorage.value,
                );
                setDisclaimerConfirmation(disclaimerStorage.value);
              }}
            >
              Confirm
            </SlButton>
          </div>
        </SlDialog>
      ) : null}
    </>
  );
};

export default Disclaimer;
