import React from 'react';
import { Link } from 'react-router-dom';
import SlIcon from '@shoelace-style/shoelace/dist/react/icon/index.js';

import styles from './ExtShellNavLink.module.css';

interface Props {
  active?: boolean;
  icon?: string;
  path?: string;
  title?: string;
}

const ExtShellNavLink: React.FC<Props> = ({
  active,
  icon,
  path = '/',
  title,
}) => {
  const classNames = active
    ? `${styles['ext-shell-nav-link']} ${styles['ext-shell-nav-link--active']}`
    : styles['ext-shell-nav-link'];

  return (
    <Link
      to={path}
      className={classNames}
      title={title}
      data-e2e="extShellNavLink"
    >
      <SlIcon name={icon} />
      <p className={styles['ext-shell-nav-link__title']}>{title}</p>
    </Link>
  );
};

export default ExtShellNavLink;
