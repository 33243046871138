import React from 'react';
import { Link } from 'react-router-dom';
import { reactifyContent } from 'piral-core';

interface Props {
  children?: string | NodeListOf<ChildNode>;
  to?: string;
}

const ExtShellLink: React.FC<Props> = ({ children, to = '/' }) => {
  return (
    <Link to={to} data-e2e="extShellLink">
      {typeof children === 'string' ? children : reactifyContent(children)}
    </Link>
  );
};

export default ExtShellLink;
