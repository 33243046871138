import React from 'react';
import { LayoutProps, useGlobalState } from 'piral-core';

const LayoutBody = React.lazy(() => import('./LayoutBody'));
const LayoutHeader = React.lazy(() => import('./LayoutHeader'));
const LayoutNav = React.lazy(() => import('./LayoutNav'));
import Disclaimer from './Disclaimer';
import Inactivity from './Inactivity';
import LoadingIndicator from './LoadingIndicator';
import styles from './Layout.module.css';

const Layout: React.FC<LayoutProps> = ({ children }) => {
  const isAuth = useGlobalState((s) => s.cxAuth.isAuth);

  return isAuth ? (
    <>
      <Disclaimer />
      <Inactivity />

      <div className={styles['layout']} data-e2e="layout">
        <div className={styles['layout__nav']}>
          <LayoutNav />
        </div>

        <div className={styles['layout__header']}>
          <LayoutHeader />
        </div>

        <div className={styles['layout__body']}>
          <LayoutBody>{children}</LayoutBody>
        </div>
      </div>
    </>
  ) : (
    <LoadingIndicator />
  );
};

export default Layout;
