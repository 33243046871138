import React, { useEffect } from 'react';
import { usePiletApi } from 'piral-core';

const inactivityEvents = [
  'click',
  'keypress',
  'mousedown',
  'mousemove',
  'mousewheel',
  'scroll',
  'touchstart',
];

interface Props {
  timeout?: number;
}

const Inactivity: React.FC<Props> = ({ timeout = 15 }) => {
  const pilet = usePiletApi();
  let timeoutId: NodeJS.Timeout;

  const addListeners = () =>
    inactivityEvents.forEach((event) =>
      document.addEventListener(event, () => restart(), true),
    );

  const removeListeners = () =>
    inactivityEvents.forEach((event) =>
      document.removeEventListener(event, () => restart(), true),
    );

  const restart = () => {
    clearTimeout(timeoutId);

    timeoutId = setTimeout(
      () => {
        pilet.cxAuth.logout();
      },
      /**
       * Timeout is provided in minutes that's why
       * we need to multiple to get milliseconds
       */
      timeout * 60000,
    );
  };

  useEffect(() => {
    // Setup on mount
    addListeners();
    restart();

    return () => {
      // Clean up after unmount
      removeListeners();
    };
  }, []);

  return <div className="inactivity" data-e2e="inactivity" />;
};

export default Inactivity;
