import { PiralPlugin } from 'piral';

interface IConfigPluginApi {
  cxConfig: {
    getEnvName(): string;
  };
}

declare module 'piral-core/lib/types/custom' {
  interface PiletCustomApi extends IConfigPluginApi {}

  interface PiralCustomState {
    cxConfig: {
      envName: string;
    };
  }
}

export function createConfigApi(): PiralPlugin<IConfigPluginApi> {
  return (context) => {
    context.dispatch((state) => ({
      ...state,
      cxConfig: {
        envName: globalThis._CORTEX_CONFIG.ENVIRONMENT,
      },
    }));

    return {
      cxConfig: {
        getEnvName() {
          return context.readState((s) => s.cxConfig.envName);
        },
      },
    };
  };
}
