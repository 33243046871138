import React from 'react';

import styles from './AccessDenied.module.css';

const AccessDenied: React.FC = () => (
  <div className={styles['access-denied']} data-e2e="accessDenied">
    <h2 className={styles['access-denied__title']}>Access Denied</h2>
    <p className={styles['access-denied__description']}>
      You don&apos;t have enough permissions to view this page
    </p>
  </div>
);

export default AccessDenied;
