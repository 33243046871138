import React from 'react';
import { ErrorInfoProps } from 'piral-core';

const ErrorInfo: React.FC<ErrorInfoProps> = ({ type }) => (
  <div className="error-info" data-e2e="errorInfo">
    {type}
  </div>
);

export default ErrorInfo;
